$(document).ready(function () {
  $(document).on('click', '.trigger-search', function () {
    $('.block-search').slideDown();
  });

  $(document).on('click', '.block-search .btn-cancel', function () {
    $('.block-search').slideUp();
  });

  $(document).on('click', '.trigger-mobile-menu', function () {
    $('.desktop-menu').slideToggle();
  });

  $(".item-thumb").hover( hoverVideo, hideVideo );

  function hoverVideo(e) {  
      console.log($(this))    
      //console.log(e)    

    $(this).find('video').get(0).play();
    $(this).find('video').get(0).controls = false
    $(this).find('img').css('opacity', 0);
  }

  function hideVideo(e) {
    $(this).find('video').get(0).pause();
    $(this).find('video').get(0).controls = true
    $(this).find('img').css('opacity', 1);
  }
});